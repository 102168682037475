import TextNew from "@atoms/TextNew";
import Image from "next/image";
import React, { useState, useEffect, useRef } from "react";
import logoUrl from "/public/assets/images/sunshine/whitelogo.svg";

const DropDown = ({ dropDownlist, dropDownname }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsHovered(false);
        setIsToggled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      className="relative inline-block text-left dropdown-container"
    >
      <TextNew
        variant="body"
        fontSize="text-xs md:text-base"
        fontWeight="!font-medium"
        fontFamily="font-Nunito"
        className="text-white focus:outline-none hover-underline no-underline"
      >
        <div
          onClick={() => setIsToggled(!isToggled)}
          className="flex items-center gap-2 cursor-pointer no-underline"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {dropDownname}
          <Image
            width={18}
            height={18}
            src="/assets/images/sunshine/ArrowDown21.svg"
            alt="Arrow down"
            className={`transform transition-transform duration-300 ${
              isToggled ? "rotate-180" : ""
            }`}
          />
        </div>
        {isHovered && <div className="hover-line"></div>}
      </TextNew>
      {isToggled && (
        <div className="dropdown-menu absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {dropDownlist.map((service, index) => (
              <a
                key={index}
                href={service.link}
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 text-[#232323] font-semibold hover:bg-[#FCE6D8]"
              >
                {service.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const HeaderDropdownCommon = ({ bgColor, services, locations , onClickContactUs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const onClickFooter = (section) => {
    console.log(`Scrolling to section: ${section}`);
  };

  const ContactUs = useRef(null);
  const onClickContact = (value) => {
    if (value === "ContactUs") {
      ContactUs.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={`py-2 px-4 md:px-0 ${bgColor}`}>
      <div className="md:container md:mx-auto flex justify-between items-center">
        <div className="hidden md:block">
          <div className="flex items-center text-white text-2xl font-bold">
            <a
              href={`https://sunshine.lissun.app/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                width={219}
                height={76}
                src={logoUrl}
                alt="Sunshine logo"
                className="ml-8 max-w-full aspect-[2.44] max-md:ml-2.5"
              />
            </a>
          </div>
        </div>

        <div className="md:hidden flex items-center justify-between w-full">
          <div className="flex items-center">
            <a
              href={`https://sunshine.lissun.app/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                width={120}
                height={45}
                src={logoUrl}
                alt="Sunshine logo"
                className="max-w-full"
              />
            </a>
          </div>
          <button className="outline-none" onClick={() => setIsOpen(!isOpen)}>
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>

        <div
          className={`fixed top-0 right-0 h-screen bg-white shadow-lg transition-transform duration-300 ease-in-out z-[1000] ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } w-8/12`}
        >
          <button
            className="absolute top-4 right-4"
            onClick={() => setIsOpen(false)}
          >
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <ul className="mt-16">
            <li>
              <a
                href={`https://sunshine.lissun.app/`}
                className="block text-sm sm:text-lg px-4 py-4 text-gray-500 hover:bg-orange-100 hover:text-black font-semibold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Home
              </a>
            </li>
            <li>
              <button
                className="block w-full text-sm sm:text-lg px-4 py-2 text-orange-500 hover:bg-orange-100 hover:text-black font-semibold flex justify-between items-center"
                onClick={() => toggleDropdown(1)}
              >
                Services
                {activeDropdown === 1 ? (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Up Arrow"
                    className="h-8 w-8 -rotate-90"
                  />
                ) : (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Down Arrow"
                    className="h-8 w-8 rotate-90"
                  />
                )}
              </button>
              {activeDropdown === 1 && (
                <ul className="bg-gray-100">
                  {services.map((service, index) => (
                    <li key={index}>
                      <a
                        href={service.link}
                        className="block text-sm sm:text-lg px-4 py-2 text-gray-500 hover:bg-orange-100 hover:text-black font-semibold"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {service.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <li>
              <button
                className="block w-full text-sm sm:text-lg px-4 py-2 text-orange-500 hover:bg-orange-100 hover:text-black font-semibold flex justify-between items-center"
                onClick={() => toggleDropdown(2)}
              >
                Locations
                {activeDropdown === 2 ? (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Up Arrow"
                    className="h-8 w-8 -rotate-90"
                  />
                ) : (
                  <Image
                    width={32}
                    height={32}
                    src="/assets/images/sunshine/arrowRight1.png"
                    alt="Down Arrow"
                    className="h-8 w-8 rotate-90"
                  />
                )}
              </button>
              {activeDropdown === 2 && (
                <ul className="bg-gray-100">
                  {locations.map((location, index) => (
                    <li key={index}>
                      <a
                        href={location.link}
                        className="block text-sm sm:text-lg px-4 py-2 text-gray-500 hover:bg-orange-100 hover:text-black font-semibold"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {location.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>

            <li>
              <a
                href="#contact-us"
                className="block text-sm sm:text-lg px-4 py-4 text-gray-500 hover:bg-orange-100 hover:text-black font-semibold"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onClickContactUs("contactUsSunshineHeader")}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        <div className="hidden md:block">
          <nav className="space-x-2 lg:space-x-8 flex items-center">
            <TextNew
              variant="body"
              fontSize="text-xs md:text-base"
              fontWeight="!font-medium"
              fontFamily="font-Nunito"
              className="hover-underline"
            >
              <a
                href={`https://sunshine.lissun.app/`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white no-underline"
              >
                Home
              </a>
            </TextNew>
            <DropDown dropDownname={"Services"} dropDownlist={services} />
            <DropDown dropDownname={"Locations"} dropDownlist={locations} />

            <TextNew
              variant="body"
              fontSize="text-xs md:text-base"
              fontWeight="!font-medium"
              fontFamily="font-Nunito"
              className="hover-underline cursor-pointer"
            >
              <a
                className="text-white no-underline whitespace-nowrap"
                // onClick={() => onClickFooter("contactUsSection")}
                onClick={() => onClickContactUs("contactUsSunshineHeader")}
              >
                Contact Us
              </a>
            </TextNew>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HeaderDropdownCommon;
