import LocationCard from "@molecules/LocationCard";
import * as React from "react";
const LocationSection = ({ locationList }) => {
    return (
        <>
            {locationList?.map((list) => (
                <LocationCard
                    key={list.location}
                    textColor={list.textColor}
                    location={list.location}
                    address={list.address}
                    phone={list.phone}
                    email={list.email}
                    viewMorelink={list.viewMorelink}
                />
            ))}
        </>
    );
};

export default LocationSection;
