import Button from "@atoms/Button";
import React, { useState } from "react";
import SelectTag from "@components/Sunshine/DropDown";
import { useForm, Controller } from "react-hook-form";
import { yupValidator } from "../lib/yup-validator";
import * as yup from "yup";
import InputGroup from "@molecules/InputGroup";
import { createSunshineVisitCenterResponse } from "@services/sunshine.service";
import { googleAdsLabel, locationOptions, preferedTimings, triggerGoogleAddConversion } from "utils/constants";
import HeadingNew from "@atoms/HeadingNew";

function BookingForm({ onClose, className }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        reset
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                name: yup
                    .string()
                    .required("Name is mandatory")
                    .matches(/^[a-zA-Z ]*$/, "Please enter valid name"),
                contact: yup
                    .string()
                    .required("Contact number is mandatory")
                    .matches(/^[6789]\d{9}$/, "Invalid phone number"),
                location: yup
                    .string().required("Location is mandatory"),
                preferedTime: yup
                    .string()
                    .required("Visit time is mandatory"),
            })
        ),
    });
    const [showError, setShowError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = (data) => {
        triggerGoogleAddConversion(googleAdsLabel.SUNSHINE_PAGE_IM_INTERESTED)
        setIsLoading(true);
        createSunshineVisitCenterResponse(data).then((res) => {
            if (res.status) {
                setIsLoading(false);
                reset({
                    name: "",
                    contact: "",
                    location: "",
                    preferedTime: ""
                });
                setShowError(null);
                onClose(true);
            } else {
                setShowError("Something went wrong !");
                setIsLoading(false);
            }
        }).catch((error) => {
            setShowError("Something went wrong !");
            setIsLoading(false);
        });
    };

    return (
        <div className={` max-w-[483px] h-full text-left ${className}`}>
            <div
                className={`relative p-4 lg:p-6 max-w-[480px] w-full bg-white mx-auto rounded-xl  `}
            >
                <HeadingNew
                    type="h4"
                    fontWeight="font-semibold"
                    className="text-left mb-6 leading-7 lg:leading-loose"
                >
                    Experience Our Sunshine Centers In Person
                </HeadingNew>
                <div className=" my-6 text-left">
                    <InputGroup
                        className={"mb-4 lg:mb-6"}
                        type="text"
                        label="Full Name"
                        register={register}
                        errors={errors}
                        inputClassName="text-sm font-normal border-none !bg-gray-100 mt-1 outline-none rounded-lg  "
                        name="name"
                        placeholder={" "}
                    />
                    <InputGroup
                        className={"mb-4 lg:mb-6"}
                        type="number"
                        label="Contact Number"
                        register={register}
                        errors={errors}
                        inputClassName=" text-sm font-normal border-none  mt-1 !bg-gray-100  outline-none rounded-lg "
                        name="contact"
                        placeholder={" "}
                    />

                    <Controller
                        control={control}
                        name="location"
                        className="outline-none "
                        render={({ field: { onChange, value } }) => (
                            <div className="mt-4 lg:mt-6 ">
                                <label className="block mb-1">
                                    Center You’d Like To Visit
                                </label>
                                <SelectTag
                                    options={locationOptions}
                                    selectedOption={
                                        { title: getValues("location") } || { title: "" }
                                    }
                                    placeholder={"Location"}
                                    setSelectedOption={(e) => {
                                        onChange(e.title);
                                    }}
                                    handleChange={(event) => { }}
                                />
                                {errors.location && (
                                    <div className="text-[#f44336] mt-1 text-xs  lowercase first-letter:capitalize  ">
                                        {errors.location.message}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name="preferedTime"
                        className="outline-none "
                        render={({ field: { onChange, value } }) => (
                            <div>
                                <label className="block mt-4 lg:mt-6">
                                    Preferred Visit Time
                                </label>
                                <SelectTag
                                    options={preferedTimings}
                                    placeholder={"Preferred Timings"}
                                    selectedOption={
                                        { title: getValues("preferedTime") } || { title: "" }
                                    }
                                    setSelectedOption={(e) => {
                                        onChange(e.title);
                                    }}
                                    handleChange={(event) => { }}
                                />
                            </div>
                        )}
                    />
                    {errors.preferedTime && (
                        <div className="text-[#f44336]  mt-1 text-xs  lowercase first-letter:capitalize  ">
                            {errors.preferedTime.message}
                        </div>
                    )}
                </div>

                {showError && (
                    <div className="mt-1 text-[#f44336]  text-base font-semibold  lowercase first-letter:capitalize ">
                        {showError}
                    </div>
                )}
                <div className="mt-8 ">
                    <Button
                        disabled={isLoading}
                        variant="sunshinePrimaryGreen"
                        onClick={handleSubmit(onSubmit)}
                        width="w-full"
                    >
                        I’m interested
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default BookingForm;
