import Container from "@atoms/Container";
import * as React from "react";
import ClientReviews from "../molecules/ClientReviews";
import Carousel from "@molecules/Carousel";
import Image from "next/image";
import HeadingNew from "@atoms/HeadingNew";
const ClientReviewSection = ({ clientReviews }) => {
  return (
    <section className="relative pt-8 lg:pt-16 bg-lightGreen z-20 overflow-hidden">
      <div className="absolute md:max-w-max  md:top-[40px] hidden md:block">
        <Image
          src="/assets/images/sunshine/vector-leaf.png"
          width={121}
          height={183}
        />
      </div>
      <div className="absolute top-[27%] md:hidden block">
        <Image
          src="/assets/images/sunshine/vector-leaf.png"
          width={35}
          height={40}
        />
      </div>
      <Container type="type12" className={"px-1 md:px-6 relative"}>
        <div className="flex justify-center">
          <div className="relative">
            <span
              className={
                "absolute md:absolute md:hidden left-24 md:-left-16 top-20 md:top-16 max-w-[36px] md:max-w-max"
              }
            >
              <Image
                src="/assets/images/sunshine/vector-butterfly-right.png"
                width={82}
                height={83}
              />
            </span>

            <HeadingNew
              type="h2"
              fontSize="text-xl lg:text-[40px]"
              className="max-w-[770px] text-center pb-[94px] md:pb-[25px] xl:pb-[68px]"
              fontWeight="font-semibold"
              fontColor="text-white"
            >
              Our Clients Talk About Their Sunshine Experience
            </HeadingNew>
          </div>
          <div className="absolute md:-top-[50px]  -right-[123px] xl:max-w-max hidden md:block">
            <Image
              src="/assets/images/sunshine/child-img-1.png"
              width={446}
              height={298}
            />
          </div>
          <div className="absolute top-[50px]  -right-[50px] md:hidden block">
            <Image
              src="/assets/images/sunshine/child-img-1.png"
              width={220}
              height={140}
            />
          </div>
        </div>
        <div className="reviews-slider">
          <Carousel
            slidesToShow={1}
            arrows={false}
            dots={false}
            autoPlay={true}
            speed={10000}
            arrowPosition={"topCenter"}
            iconBg=""
            iconSize="w-9 md:w-16 h-9 md:h-16"
            leftArrowIcon={
              <Image
                src="/assets/images/sunshine/leftArrow.svg"
                width={64}
                height={64}
                alt=""
              />
            }
            rightArrowIcon={
              <Image
                src="/assets/images/sunshine/rightArrow.svg"
                width={64}
                height={64}
                alt=""
              />
            }
            prevIconClass="relative left-0 xl:-left-5"
            nextIconClass="relative right-0 xl:-right-5"
            responsive={[
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  speed: 2500,
                },
              },
              {
                breakpoint: 2573,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                  speed: 10000,
                },
              },
            ]}
          >
            {clientReviews?.map((clientReview) => (
              <div className="px-0 md:px-3 xl:px-2">
                <ClientReviews
                  key={clientReview.name}
                  imageUrl={clientReview.image}
                  name={clientReview.name}
                  content={clientReview.content}
                  location={clientReview.location}
                />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="relative -bottom-5 mt-12">
          <Image
            src="/assets/images/sunshine/vectorlion.svg"
            width={60}
            height={80}
            alt=""
          />
        </div>
      </Container>
    </section>
  );
};

export default ClientReviewSection;
